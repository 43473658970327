import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import {
  CatalogServiceDto,
  ServiceType,
  OfferedAsType,
  ActiveFeatures,
} from '@wix/bookings-uou-types';
import { isFeatureEnabled } from '@wix/bookings-config/dist/src/active-features/feature-enabler';
import { BookingsApi } from '../../api/BookingsApi';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ServiceIntent } from '../../consts';
import {
  isDemoMode,
  isTemplateMode,
  isPricingPlanInstalled as isPricingPlanInstalledUtils,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export const enum pricingPlanConst {
  PAGE_NOT_INSTALLED = 'PageNotInstalled',
  NO_PLANS_ASSIGNED_TO_OFFERING = 'NoPlansAssignedToOffering',
}

export interface ShouldNavigateResponse {
  canBook: boolean;
  reason?: ShouldNavigateReason;
}

export interface ShouldNavigateReason {
  premiumError: boolean;
  pricingPlanError: boolean;
  isServiceConnectedToPricingPlans: boolean;
  isPricingPlanInstalled: boolean;
  siteNotEcomError: boolean;
}

export type CanBookResult = {
  canBook: boolean;
  reason: ShouldNavigateReason;
};

export class BookingValidations {
  private isUseUtilsInsteadOfWixSDKEnabled: boolean;
  constructor(
    private readonly wixSdkAdapter: WixOOISDKAdapter,
    private readonly bookingsApi: BookingsApi,
    private readonly flowAPI: ControllerFlowAPI,
  ) {
    this.isUseUtilsInsteadOfWixSDKEnabled = this.flowAPI.experiments.enabled(
      'specs.bookings.isUseUtilsInsteadOfWixSDKEnabled',
    );
  }

  async shouldNavigate(
    service: CatalogServiceDto,
    activeFeatures: ActiveFeatures,
    intent: ServiceIntent,
  ): Promise<ShouldNavigateResponse> {
    if (
      intent === ServiceIntent.SHOW_DETAILS ||
      this.flowAPI.environment.isPreview ||
      (this.isUseUtilsInsteadOfWixSDKEnabled
        ? isDemoMode({
            wixCodeApi: this.flowAPI.controllerConfig.wixCodeApi,
            appParams: this.flowAPI.controllerConfig.appParams,
          })
        : this.wixSdkAdapter.isDemoMode()) ||
      (this.isUseUtilsInsteadOfWixSDKEnabled
        ? isTemplateMode({
            wixCodeApi: this.flowAPI.controllerConfig.wixCodeApi,
            appParams: this.flowAPI.controllerConfig.appParams,
          })
        : this.wixSdkAdapter.isTemplateMode())
    ) {
      return { canBook: true };
    }
    return this.canBook(service, activeFeatures);
  }

  canBook(
    service: CatalogServiceDto,
    activeFeatures: ActiveFeatures,
  ): Promise<CanBookResult> {
    const isPricingPlanInstalledPromise = this.isUseUtilsInsteadOfWixSDKEnabled
      ? isPricingPlanInstalledUtils(this.flowAPI.controllerConfig.wixCodeApi)
      : this.wixSdkAdapter.isPricingPlanInstalled();

    return isPricingPlanInstalledPromise.then(
      async (isPricingPlanInstalled) => {
        const couldBePremium = await this.couldBePremiumNavigate(
          service,
          activeFeatures,
        );
        const couldBePricingPlan = couldBePricingPlanNavigate(
          service,
          isPricingPlanInstalled,
        );

        const siteIsEcom = await this.bookingsApi.isEcomSite();

        return {
          canBook: couldBePremium && couldBePricingPlan.canBook && siteIsEcom,
          reason: {
            premiumError: !couldBePremium,
            pricingPlanError: !couldBePricingPlan.canBook,
            isServiceConnectedToPricingPlans:
              couldBePricingPlan.isServiceConnectedToPricingPlans,
            isPricingPlanInstalled,
            siteNotEcomError: !siteIsEcom,
          },
        };
      },
    );
  }

  private async couldBePremiumNavigate(
    service: CatalogServiceDto,
    activeFeatures: ActiveFeatures,
  ): Promise<boolean> {
    return (
      service.type === ServiceType.COURSE ||
      isFeatureEnabled(activeFeatures, service.type)
    );
  }
}

function couldBePricingPlanNavigate(
  service: CatalogServiceDto,
  isPricingPlanInstalled: boolean,
): {
  canBook: boolean;
  isServiceConnectedToPricingPlans: boolean;
} {
  const isServiceConnectedToPricingPlans =
    isServiceConnectedToPricingPlan(service);

  const serviceOfferedAsPricingPlan = isServiceOfferedAsPricingPlan(
    service,
    isPricingPlanInstalled,
  );
  return {
    canBook:
      !serviceOfferedAsPricingPlan ||
      (isServiceConnectedToPricingPlans && isPricingPlanInstalled),
    isServiceConnectedToPricingPlans,
  };
}

const isServiceConnectedToPricingPlan = (service: CatalogServiceDto) => {
  return !!(
    service.pricingPlanInfo &&
    service.pricingPlanInfo.pricingPlans &&
    service.pricingPlanInfo.pricingPlans.length
  );
};

const isServiceOfferedAsPricingPlan = (
  offeringViewModel: CatalogServiceDto,
  isPricingPlanInstalled: boolean,
) =>
  getOfferedAs(offeringViewModel, isPricingPlanInstalled).indexOf(
    OfferedAsType.PRICING_PLAN,
  ) > -1;

const getOfferedAs = (
  service: CatalogServiceDto,
  isPricingPlanInstalled: boolean,
): OfferedAsType[] => {
  if (
    service.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0 &&
    service.offeredAs.indexOf(OfferedAsType.PRICING_PLAN) >= 0
  ) {
    if (service.pricingPlanInfo.pricingPlans.length === 0) {
      return [OfferedAsType.ONE_TIME];
    }
    if (!isPricingPlanInstalled) {
      return [OfferedAsType.ONE_TIME];
    }
  }
  return service.offeredAs;
};

export const getNotifyPricingPlanRequest = (
  service: CatalogServiceDto,
  reason: ShouldNavigateReason,
) => {
  const reasons = [];
  if (!reason.isPricingPlanInstalled) {
    reasons.push(pricingPlanConst.PAGE_NOT_INSTALLED);
  }
  if (!reason.isServiceConnectedToPricingPlans) {
    reasons.push(pricingPlanConst.NO_PLANS_ASSIGNED_TO_OFFERING);
  }
  const offeringId = service.id;
  return { reasons, offeringId };
};
